<template>
  <div class="container">
    <div
      v-if="hasRole('teacher') || hasPermission('access_dashboard')"
      class="text-center pt-5"
      v-loading="loading"
    >
      <div class="card py-5"
      >
        <h4 class="pb-5">
          <i class="fa fa-users"></i>
          {{ $t("admin_dashboard_type.students_number") }} :
          {{ students.length }}
        </h4>

        <div class="mb-3">
          <p>
            <i class="fa fa-book" aria-hidden="true"></i>
            {{ $t("admin_dashboard_type.pre_exam") }}
          </p>
          <table class="table bg-white">
            <thead class="text-center">
              <tr>
                <th scope="col">{{ $t("Average originality") }}</th>
                <th scope="col">{{ $t("Average fluency") }}</th>

                <th scope="col">{{ $t("Average flexibility") }}</th>
              </tr>
            </thead>

            <tbody class="text-center px-5">
              <tr>
                <td>
                  <i class="fa fa-angle-double-left"></i>
                  {{ scoreChart.Average_score }} / 26
                </td>
                <td>
                  <i class="fa fa-angle-double-left"></i>
                  {{ fluencyChart.Average_fluency }} / 135
                </td>

                <td>
                  <i class="fa fa-angle-double-left"></i>
                  {{ categoryChart.Average_categories }} / 16
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="py-5">
          <p>
            <i class="fa fa-book" aria-hidden="true"></i
            >{{ $t("admin_dashboard_type.post_exam") }}
          </p>
          <table class="table bg-white">
            <thead class="text-center">
              <tr>
                <th scope="col">{{ $t("Average originality") }}</th>
                <th scope="col">{{ $t("Average fluency") }}</th>
                <th scope="col">{{ $t("Average flexibility") }}</th>
              </tr>
            </thead>

            <tbody class="text-center px-5">
              <tr>
                <td>
                  <i class="fa fa-angle-double-left"></i>
                  {{ scoreChartSecond.Average_score }} / 26
                </td>
                <td>
                  <i class="fa fa-angle-double-left"></i>
                  {{ fluencyChartSecond.Average_fluency }} / 135
                </td>

                <td>
                  <i class="fa fa-angle-double-left"></i>
                  {{ categoryChartSecond.Average_categories }} / 16
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justifiy-content-between flex-wrap">
          <div
            class="mx-5 pb-5 mb-5"
            v-if="hasRole('teacher') || hasPermission('access_dashboard')"
          >
            <p>{{ $t("admin_dashboard_type.pre_exam_result") }}</p>
            <div>
              <apexchart
                width="400"
                type="bar"
                :options="scoreChart.options"
                :series="scoreChart.series"
              ></apexchart>
            </div>
            <div>
              <apexchart
                width="400"
                type="bar"
                :options="fluencyChart.options"
                :series="fluencyChart.series"
              ></apexchart>
            </div>
            <div>
              <apexchart
                width="400"
                type="bar"
                :options="categoryChart.options"
                :series="categoryChart.series"
              ></apexchart>
            </div>
          </div>

          <div
            class="pb-5 mb-5 mr-5"
            v-if="hasRole('teacher') || hasPermission('access_dashboard')"
          >
            <p>{{ $t("admin_dashboard_type.post_exam_result") }}</p>
            <div>
              <apexchart
                width="400"
                type="bar"
                :options="scoreChartSecond.options"
                :series="scoreChartSecond.series"
              ></apexchart>
            </div>
            <div>
              <apexchart
                width="400"
                type="bar"
                :options="fluencyChartSecond.options"
                :series="fluencyChartSecond.series"
              ></apexchart>
            </div>
            <div>
              <apexchart
                width="400"
                type="bar"
                :options="categoryChartSecond.options"
                :series="categoryChartSecond.series"
              ></apexchart>
            </div>
          </div>
        </div>

        <div
          class="card"
          v-if="hasRole('teacher') || hasPermission('access_dashboard')"
        >
          <div class="border">
            <el-table v-loading="loading" :data="students">
              <el-table-column type="expand"> </el-table-column>
              <el-table-column
                :label="$t('admin_dashboard_type.name')"
                prop="name"
                :align="right"
              />
              <el-table-column
                :label="$t('admin_dashboard_type.pre_exam')"
                min-width="50"
              >
                <template slot-scope="scope">
                  <router-link
                    v-if="scope.row.pre_exam == null || scope.row.pre_exam <= 0"
                    :to="`/exams/${exam_type_1}/${scope.row.student_id}`"
                    style="width: 100%"
                  >
                    <el-button
                      class="btn btn-primary"
                      @click="viewExam(scope.row.student_id, 1, exam_type_1)"
                    >
                      {{ $t("admin_dashboard_type.pre_exam") }}
                    </el-button>
                  </router-link>
                  <router-link
                    style="width: 100%"
                    v-if="scope.row.pre_exam !== null || scope.row.pre_exam > 0"
                    :to="`/report/${scope.row.student_id}/${exam_type_1}`"
                  >
                    <el-button
                      class="btn btn-secondary"
                      @click="viewExam(scope.row.student_id, 1, exam_type_1)"
                    >
                      {{ $t("admin_dashboard_type.pre_exam_result") }}
                    </el-button>
                  </router-link>
                </template>
              </el-table-column>

              <el-table-column
                prop="student_id"
                :label="$t('admin_dashboard_type.post_exam')"
                min-width="50"
                :align="right"
              >
                <template slot-scope="scope">
                  <router-link
                    v-if="
                      scope.row.last_exam == null || scope.row.last_exam <= 0
                    "
                    :to="`/exams/${exam_type_2}/${scope.row.student_id}`"
                    style="width: 100%"
                  >
                    <el-button
                      @click="viewExam(scope.row.student_id, 2, exam_type_2)"
                    >
                      {{ $t("admin_dashboard_type.post_exam") }}
                    </el-button>
                  </router-link>

                  <router-link
                    v-if="
                      scope.row.last_exam !== null || scope.row.last_exam > 0
                    "
                    :to="`/report/${scope.row.student_id}/${exam_type_2}`"
                    style="width: 100%"
                  >
                    <el-button
                      class="btn btn-secondary"
                      @click="viewExam(scope.row.student_id, 2, exam_type_2)"
                    >
                      {{ $t("admin_dashboard_type.post_exam_result") }}
                    </el-button>
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card"
      v-if="hasRole('student') || hasPermission('access_dashboard')"
    >
      <template>
        <el-table v-loading="loading" :data="child" style="width: 100%">
          <el-table-column
            prop="الأسم"
            :label="$t('admin_dashboard_type.name')"
            width="180"
          />
          <el-table-column label=" الاختبار الاول" min-width="50">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.pre_exam == null"
                :to="`/exam/${exam_type_1}/${scope.row.id}`"
                style="width: 100%"
              >
                <el-button
                  class="btn btn-primary"
                  @click="viewExam(scope.row.id, 1, exam_type_1)"
                >
                  اختبار أول
                </el-button>
              </router-link>
              <router-link
                v-if="scope.row.pre_exam != null"
                :to="`/report/${scope.row.id}/${exam_type_1}`"
                style="width: 100%"
              >
                <el-button class="btn btn-primary"> نتيجة الاختبار </el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="student_id"
            label="الاختبار الاخير"
            min-width="50"
            :align="right"
          >
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.last_exam == null"
                :to="`/exam/${exam_type_2}/${scope.row.id}`"
                style="width: 100%"
              >
                <el-button> اختبار اخير </el-button>
              </router-link>

              <router-link
                v-if="scope.row.last_exam != null"
                :to="`/report/${scope.row.id}/${exam_type_2}`"
                style="width: 100%"
              >
                <el-button> اختبار اخير </el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="حذف الاختبار" min-width="50" :align="right">
            <el-button class="btn btn-danger"> حذف الاختبار </el-button>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>

<script>
import { ChartCard, StatsCard } from "src/components/index";
//import profile from "../../../views/users/show";
import ApexCharts from "apexcharts";
import VueApexCharts from "vue-apexcharts";
import { sum } from "d3";

// Username:           justtolearn
// Password: qqwqngqmm#D#n2nBvz
// dist -well known- api- htaccess -stories
// file ma- public-----
export default {
  components: {
    ChartCard,
    StatsCard,
    VueApexCharts,
    ApexCharts,
  },
  name: "exams",
  data() {
    return {
      students_ids: [],
      parent_students: [],
      all_students: [],
      student_cat: null,
      student_score: null,
      student_fluency: null,
      totalCount: null,
      exam_type_1: 1,
      exam_type_2: 2,
      id: this.auth().id,
      pre_exam: null,
      last_exam: null,
      disabled: true,
      disabled2: true,
      children: [],
      first_exam_result: [],
      second_exam_result: [],
      categories: [],
      score: [],
      fluency: [],
      students: [],
      second_categories: [],
      second_score: [],
      second_fluency: [],
      loading: false,
    };
  },
  mounted() {
    this.$store.dispatch("user/user", this.id).then((res) => {});
    // this.$store.dispatch("groups/studentscore", 1).then((response) => {
    //   this.first_exam_result = response;
    //   this.categories = response.categories;
    //   this.score = response.score;
    //   this.fluency = response.fluency;
    //   this.students = response.user_data;
    //   console.log(this.students);
    // });
    this.getStudentScoreOne();
    this.$store.dispatch("groups/studentscore", 2).then((response) => {
      this.second_exam_result = response;
      this.second_categories = response.categories;
      this.second_score = response.score;
      this.second_fluency = response.fluency;
      // this.loading = false;

    });
  },
  computed: {
    fluencyChart() {
      let students = [];
      let fluency = [];
      let max_fluency = 0;
      let Average_fluency = 0;
      let sum_fluency = 0;

      for (let i = 0; i < this.fluency.length; i++) {
        fluency.push(this.fluency[i].score);
      }
      // console.log(resss.fluency);
      max_fluency = parseInt(Math.max(...fluency));

      for (let i = 0; i < fluency.length; i++) {
        sum_fluency += fluency[i];
      }

      Average_fluency = parseInt((sum_fluency / fluency.length).toFixed(2));
      console.log(Average_fluency);
      let x = [max_fluency, "", ""];

      return {
        max_fluency,
        Average_fluency,

        options: {
          annotations: {
            points: [
              {
                x: 40,
                y: Average_fluency,
                marker: {
                  size: 4,
                  width: "50px",
                },
                label: {
                  borderColor: "#84898f",
                  text: `${this.$t("Average")}`,
                },
              },
            ],
          },
          chart: {
            id: "vuechart-example",
          },
          xaxis: {
            categories: [`${this.$t("fluency")}`, "", ""],
          },
          yaxis: {
            min: 0,
            max: max_fluency,
            tickAmount: 10,
          },
        },
        labels: this.students,
        series: [
          {
            name: "series-1",
            data: x,
          },
        ],
      };
    },
    categoryChart() {
      let students = [];
      let categories = [];
      let max_categories = 0;
      let Average_categories = 0;
      let sum_categories = 0;

      for (let i = 0; i < this.categories.length; i++) {
        categories.push(this.categories[i].count);
        sum_categories += this.categories[i].count;
      }

      max_categories = parseInt(Math.max(...categories));
      Average_categories = (sum_categories / categories.length).toFixed(2);
      let x = [max_categories, "", ""];

      return {
        max_categories,
        Average_categories,

        options: {
          annotations: {
            points: [
              {
                x: 40,
                y: Average_categories,
                marker: {
                  size: 4,
                  width: "50px",
                },
                label: {
                  borderColor: "#84898f",
                  text: `${this.$t("Average")}`,
                },
              },
            ],
          },
          chart: {
            id: "vuechart-example",
          },
          xaxis: {
            categories: [`${this.$t("flexibility")}`, "", ""],
          },
          yaxis: {
            min: 0,
            max: max_categories,
            tickAmount: 5,
          },
        },
        labels: this.students,
        series: [
          {
            name: "series-1",
            data: x,
          },
        ],
      };
    },
    scoreChart() {
      let scores = [];
      let max_score = 0;
      let students = [];
      let Average_score = 0;

      let sum_score = 0;

      for (let i = 0; i < this.score.length; i++) {
        scores.push(this.score[i].total);
        students.push(this.score[i].name);
      }
      max_score = parseInt(Math.max(...scores));
      for (let i = 0; i < scores.length; i++) {
        sum_score += scores[i];
      }

      Average_score = parseInt((sum_score / scores.length).toFixed(2));
      let x = [max_score, "", ""];

      return {
        max_score,
        Average_score,
        options: {
          annotations: {
            points: [
              {
                x: 40,
                y: Average_score,
                marker: {
                  size: 4,
                  width: "50px",
                },
                label: {
                  borderColor: "#84898f",
                  text: `${this.$t("Average")}`,
                },
              },
            ],
          },
          chart: {
            id: "vuechart-example",
          },
          xaxis: {
            categories: [`${this.$t("originality")}`, "", ""],
          },
          yaxis: {
            min: 0,
            max: max_score,
            tickAmount: 10,
          },
        },
        labels: students,
        series: [
          {
            name: "series-1",
            data: x,
          },
        ],
      };
    },

    fluencyChartSecond() {
      let students = [];
      let fluency = [];
      let max_fluency = 0;
      let Average_fluency = 0;
      let sum_fluency = 0;

      for (let i = 0; i < this.second_fluency.length; i++) {
        fluency.push(this.second_fluency[i].score);
      }
      // console.log(resss.fluency);
      max_fluency = parseInt(Math.max(...fluency));

      for (let i = 0; i < fluency.length; i++) {
        sum_fluency += fluency[i];
      }

      Average_fluency = parseInt((sum_fluency / fluency.length).toFixed(2));

      let x = [max_fluency, "", ""];

      return {
        max_fluency,
        Average_fluency,

        options: {
          annotations: {
            points: [
              {
                x: 40,
                y: Average_fluency,
                marker: {
                  size: 4,
                  width: "50px",
                },
                label: {
                  borderColor: "#84898f",
                  text: `${this.$t("Average")}`,
                },
              },
            ],
          },
          chart: {
            id: "vuechart-example",
          },
          xaxis: {
            categories: [`${this.$t("fluency")}`, "", ""],
          },
          yaxis: {
            min: 0,
            max: max_fluency,
            tickAmount: 10,
          },
        },
        labels: this.students,
        series: [
          {
            name: "series-1",
            data: x,
          },
        ],
      };
    },
    categoryChartSecond() {
      let students = [];
      let categories = [];
      let max_categories = 0;
      let Average_categories = 0;
      let sum_categories = 0;

      for (let i = 0; i < this.second_categories.length; i++) {
        categories.push(this.second_categories[i].count);
        sum_categories += this.second_categories[i].count;
      }

      max_categories = parseInt(Math.max(...categories));
      Average_categories = (sum_categories / categories.length).toFixed(2);
      let x = [max_categories, "", ""];

      return {
        max_categories,
        Average_categories,

        options: {
          annotations: {
            points: [
              {
                x: 40,
                y: Average_categories,
                marker: {
                  size: 4,
                  width: "50px",
                },
                label: {
                  borderColor: "#84898f",
                  text: `${this.$t("Average")}`,
                },
              },
            ],
          },
          chart: {
            id: "vuechart-example",
          },
          xaxis: {
            categories: [`${this.$t("flexibility")}`, "", ""],
          },
          yaxis: {
            min: 0,
            max: max_categories,
            tickAmount: 5,
          },
        },
        labels: this.students,
        series: [
          {
            name: "series-1",
            data: x,
          },
        ],
      };
    },
    scoreChartSecond() {
      let scores = [];
      let max_score = 0;
      let students = [];
      let Average_score = 0;

      let sum_score = 0;

      for (let i = 0; i < this.second_score.length; i++) {
        scores.push(this.second_score[i].total);
        students.push(this.second_score[i].name);
      }
      max_score = parseInt(Math.max(...scores));
      for (let i = 0; i < scores.length; i++) {
        sum_score += scores[i];
      }

      Average_score = parseInt((sum_score / scores.length).toFixed(2));
      let x = [max_score, "", ""];

      return {
        max_score,
        Average_score,
        options: {
          annotations: {
            points: [
              {
                x: 40,
                y: Average_score,
                marker: {
                  size: 4,
                  width: "50px",
                },
                label: {
                  borderColor: "#84898f",
                  text: `${this.$t("Average")}`,
                  // text: "المتوسط",
                },
              },
            ],
          },
          chart: {
            id: "vuechart-example",
          },
          xaxis: {
            categories: [`${this.$t("originality")}`, "", ""],
          },
          yaxis: {
            min: 0,
            max: max_score,
            tickAmount: 10,
          },
        },
        labels: students,
        series: [
          {
            name: "series-1",
            data: x,
          },
        ],
      };
    },
    child() {
      let childrn = this.$store.getters["user/child"];

      return childrn;
    },
  },
  methods: {
    viewExam(id, group_no, exam_type) {
      const examData = {
        id: id,
        group_no: group_no,
        exam_type: exam_type,
      };
      localStorage.setItem("data", JSON.stringify(examData));
    },
    getStudentScoreOne() {
      this.loading = true;
      this.$store.dispatch("groups/studentscore", 1).then((response) => {
        this.first_exam_result = response;
        this.categories = response.categories;
        this.score = response.score;
        this.fluency = response.fluency;
        this.students = response.user_data;

        this.loading = false;
      });
    },
  },
};
</script>

<style scoped></style>
